var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.clonedAttributeValues.length)?_c('div',{ref:"tableFrame",class:_vm.$style.tableFrame},[(_vm.getGroupLevelAttributeValuesByGroupIdState.loading)?_c('span',[_c('p',[_vm._v("Loading group attributes...")])]):_c('b-field',{staticClass:"has-text-dark",class:_vm.$style.sectionLabel,attrs:{"label":"GROUP ATTRIBUTES"}}),_c('b-table',{attrs:{"data":_vm.clonedAttributeValues}},[_c('b-table-column',{attrs:{"field":"label","label":"Label","width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(_vm._s(props.row.label))]}}],null,false,1265490075)}),_c('b-table-column',{attrs:{"field":"value","label":"Value"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(_vm.attributeType(props.row) === 'images')?_c('div',[(props.row.value && props.row.value.length)?_c('div',{class:_vm.$style.images},_vm._l((props.row.value),function(imgUrl,imgUrlIndex){return _c('div',{key:imgUrlIndex,class:_vm.$style.image,style:({
                backgroundImage: ("url(" + imgUrl + ")")
              }),on:{"click":function($event){return _vm.downloadFile(imgUrl)}}})}),0):_c('div',[_c('i',{class:_vm.$style.noValue},[_vm._v("No file uploaded.")])])]):_vm._e(),(_vm.attributeType(props.row) === 'address')?_c('div',[(_vm.renderAddress(props.row).length)?_c('p',[_vm._v(" "+_vm._s(_vm.renderAddress(props.row))+" ")]):_c('i',{class:_vm.$style.noValue},[_vm._v("No value.")])]):_vm._e(),(_vm.attributeType(props.row) === 'coordinates')?_c('div',[(props.row.value[0].value && props.row.value[1].value)?_c('a',{class:_vm.$style.links,attrs:{"href":_vm.getGoogleMapsUrl(
                props.row.value[0].value,
                props.row.value[1].value
              ),"target":"_blank"}},[_vm._v(" "+_vm._s(props.row.value[0].value)+", "+_vm._s(props.row.value[1].value)+" ")]):_c('p',[(props.row.value[0].value)?_c('span',[_vm._v(" "+_vm._s(props.row.value[0].value)+", ")]):_c('i',{class:_vm.$style.noValue},[_vm._v("No latitude, ")]),(props.row.value[1].value)?_c('span',[_vm._v(" "+_vm._s(props.row.value[1].value)+" ")]):_c('i',{class:_vm.$style.noValue},[_vm._v("No longitude")])])]):_vm._e(),(_vm.attributeType(props.row) === 'list')?_c('div',[(props.row.value.options && props.row.value.options.length)?_c('div',[(Array.isArray(props.row.value.selected))?_c('div',[_c('ul',{class:_vm.$style.selections},_vm._l((props.row.value.options),function(listItem,listIndex){return _c('li',{key:listIndex},[_c('b-icon',{class:[
                      [_vm.$style.selectionIcons],
                      props.row.value.selected.includes(listItem)
                        ? _vm.$style['is-selected']
                        : ''
                    ],attrs:{"icon":props.row.value.selected.includes(listItem)
                        ? 'checkbox-marked'
                        : 'checkbox-blank-outline',"size":"small"}}),_c('span',[_vm._v(" "+_vm._s(listItem)+" ")])],1)}),0)]):_c('div',[_c('ul',{class:_vm.$style.selections},_vm._l((props.row.value.options),function(listItem,listIndex){return _c('li',{key:listIndex},[_c('b-icon',{class:[
                      [_vm.$style.selectionIcons],
                      props.row.value.selected.includes(listItem)
                        ? _vm.$style['is-selected']
                        : ''
                    ],attrs:{"icon":props.row.value.selected === listItem
                        ? 'radiobox-marked'
                        : 'radiobox-blank',"size":"small"}}),_c('span',[_vm._v(" "+_vm._s(listItem)+" ")])],1)}),0)])]):(
              props.row.value.options && props.row.value.options.length === 0
            )?_c('i',{class:_vm.$style.noValue},[_vm._v(" Empty list. ")]):_vm._e()]):_vm._e(),(_vm.attributeType(props.row) === 'documents')?_c('div',[(props.row.value.length)?_c('ul',{class:_vm.$style.lists},_vm._l((props.row.value),function(documentUrl,documentUrlIndex){return _c('li',{key:documentUrlIndex},[_c('a',{class:_vm.$style.links,on:{"click":function($event){return _vm.downloadFile(documentUrl)}}},[_vm._v(" "+_vm._s(documentUrl.split('/').pop())+" ")])])}),0):_c('i',{class:_vm.$style.noValue},[_vm._v("No document uploaded.")])]):_vm._e(),(_vm.attributeType(props.row) === 'link')?_c('div',[(props.row.value !== '' && props.row.value !== null)?_c('a',{class:_vm.$style.links,attrs:{"href":props.row.value,"target":"_blank"}},[_vm._v(" "+_vm._s(props.row.value)+" ")]):_c('i',{class:_vm.$style.noValue},[_vm._v("No value.")])]):_vm._e(),(_vm.attributeType(props.row) === 'text area')?_c('div',{class:_vm.$style.textArea},[(props.row.value !== '' && props.row.value !== null)?_c('div',{staticClass:"ql-editor",domProps:{"innerHTML":_vm._s(props.row.value)}}):_c('i',{class:_vm.$style.noValue},[_vm._v("No value.")])]):_vm._e(),(_vm.attributeType(props.row) === 'date')?_c('div',[(props.row.value !== '' && props.row.value !== null)?_c('p',[_vm._v(" "+_vm._s(_vm.parseDate(props.row.value))+" ")]):_c('i',{class:_vm.$style.noValue},[_vm._v("No value.")])]):_vm._e(),(
            _vm.attributeType(props.row) === 'text' ||
            _vm.attributeType(props.row) === 'email' ||
            _vm.attributeType(props.row) === 'integer' ||
            _vm.attributeType(props.row) === 'boolean' ||
            _vm.attributeType(props.row) === 'phone'
          )?_c('div',[(props.row.value !== '' && props.row.value !== null)?_c('p',[_vm._v(" "+_vm._s(props.row.value)+" ")]):_c('i',{class:_vm.$style.noValue},[_vm._v("No value.")])]):_vm._e()]}}],null,false,2222864379)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }